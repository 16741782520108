import { useStaticQuery, graphql } from 'gatsby'
export const useSiteMetadata = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    discordInvite
                    title
                    links {
                        discord {
                            name
                            url
                        }
                        youtube {
                            name
                            url
                        }
                    }
                }
            }
        }
    `)

    return data.site.siteMetadata
}
