import React from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { useSiteMetadata } from '../hooks/site-metadata.js'

const Footer = () => {
    const {
        links: { youtube , discord},
    } = useSiteMetadata()

    return (
        <footer className="relative z-10 bg-gray-800 sm:text-center text-gray-400 py-8 px-4">
            <div className="sm:grid grid-flow-col gap-8 justify-center">
                <Link className="py-2 block hover:underline hover:text-gray-100" to="/">
                    Home
                </Link>
                <Link className="py-2 block hover:underline hover:text-gray-100" to="/courses">
                    Courses
                </Link>
                <OutboundLink
                    target="_blank"
                    rel="noopener"
                    href={discord.url}
                    className="py-2 block group items-center hover:underline hover:text-gray-100">
                    <h4 className="">Discord</h4>
                </OutboundLink>
                <OutboundLink
                    target="_blank"
                    rel="noopener"
                    href={youtube.url}
                    className="py-2 block group items-center hover:underline hover:text-gray-100">
                    <h4 className="">YouTube</h4>
                </OutboundLink>
                <Link to="/privacy" className="py-2 block hover:underline hover:text-gray-100">
                    Privacy Policy
                </Link>
                <Link to="/terms" className="py-2 block hover:underline hover:text-gray-100">
                    Terms of Service
                </Link>
            </div>
            <div className="border border-gray-700 my-2"></div>
            <div className="text-sm">
                <span>&copy;2020 IndieNuggets.com. All rights reserved.</span>
            </div>
        </footer>
    )
}

export default Footer
