import React from "react";
import { Link, navigate } from "gatsby";
import { FirebaseContext } from "gatsby-plugin-firebase";
import { useOnClickOutside } from "../hooks/on-click-outside.js";
import { useSession } from "../util/context.js";
import { useSiteMetadata } from "../hooks/site-metadata.js";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import constants from "../constants";

export default (props) => {
  const { discordInvite } = useSiteMetadata();

  const [open, setOpen] = React.useState(false);
  const { user } = useSession();

  const ref = React.useRef();
  useOnClickOutside(ref, () => setOpen(false));

  const fb = React.useContext(FirebaseContext);
  const handleLogout = (e) => {
    if (!fb) return;
    navigate("/"); //Navigate before so other App page doesn't redirect us to /login again
    fb.auth().signOut();
  };

  if (!user) return null;
  return (
    <div ref={ref} className="relative">
      <div>
        <span className="rounded-md">
          <button
            aria-label="Toggle Profile Dropdown"
            onClick={() => setOpen(!open)}
            type="button"
            className="inline-flex justify-center h-10 w-10 rounded-full overflow-hidden shadow-solid text-gray-600 hover:text-gray-800 focus:outline-none focus:border-gray-300 focus:shadow-outline active:text-gray-900 transition ease-in-out duration-150"
          >
            {user.photoURL ? (
              <img
                className="inline-block h-full w-full text-white"
                width="24"
                height="24"
                src={user.photoURL}
                alt=""
              />
            ) : (
              <svg
                className="h-5 w-5 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            )}
          </button>
        </span>
      </div>

      {open && (
        <div className="origin-top-right absolute overflow-hidden right-0 mt-1 w-56 rounded-md shadow-lg">
          <div className="rounded-md bg-white shadow-xs">
            <div className="py-1">
              <p className="block px-4 py-3 text-sm leading-5 text-gray-700">
                <span className="text-xs">Logged in as </span>
                <span className="font-medium text-gray-700">{user.email}</span>
              </p>
            </div>
            <div className="border-t border-gray-100" />
            <div className="py-0">
              <Link
                to="/app/my-courses"
                className="group block px-4 py-3 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              >
                <span className="absolute left-0 inset-y pl-3">
                  <svg
                    className="h-5 w-5 group-hover:text-teal-500 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                    />
                  </svg>
                </span>
                <span className="pl-6">My Courses</span>
              </Link>
              <OutboundLink
                target="_blank"
                rel="noopener"
                href={`mailto:${constants.EMAIL}`}
                className="group block px-4 py-3 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              >
                <span className="absolute left-0 inset-y pl-3">
                  <svg
                    className="h-5 w-5 group-hover:text-teal-500 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                </span>
                <span className="pl-6">Email Support</span>
              </OutboundLink>
              <OutboundLink
                target="_blank"
                rel="noopener"
                href={discordInvite}
                className="group block px-4 py-3 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              >
                <span className="absolute left-0 inset-y pl-2">
                  <svg
                    className="h-6 w-7 fill-current group-hover:text-teal-500 text-gray-400"
                    viewBox="0 0 245 240"
                  >
                    <path d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z" />
                    <path d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z" />
                  </svg>
                </span>
                <span className="pl-6">Discord</span>
              </OutboundLink>
            </div>
            <div className="border-t border-gray-100" />
            <div className="py-0">
              <button
                onClick={handleLogout}
                className="group block relative w-full text-left px-4 py-3 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              >
                <span className="absolute left-0 inset-y pl-3">
                  <svg
                    className="h-5 w-5 group-hover:text-teal-500 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                    />
                  </svg>
                </span>
                <span className="pl-6">Logout</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
