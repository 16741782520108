import { Link, navigate } from 'gatsby'
import React from 'react'
import { useSession } from '../util/context.js'
import { useSiteMetadata } from '../hooks/site-metadata.js'
import ProfileDropdown from '../components/profile-dropdown.js'
import { FirebaseContext } from 'gatsby-plugin-firebase'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

//@Todo persist nav open close
//@Todo change nav open/close icon
export default props => {
    const {
        title,
        links: { youtube },
    } = useSiteMetadata()
    const { user } = useSession()

    const [navOpen, setNavOpen] = React.useState(false)

    const fb = React.useContext(FirebaseContext)
    const handleLogout = e => {
        if (!fb) return
        navigate('/') //Navigate before so other App page doesn't redirect us to /login again
        fb.auth().signOut()
    }
    const fixedClass = props.dontFixHeader ? 'block' : 'sm:fixed top-0 z-50'

    return (
        <header
            className={`${fixedClass} z-50 w-full max-w-screen bg-white shadow sm:flex items-center justify-between`}>
            <nav className="w-full">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center flex-1">
                            <Link
                                to="/"
                                className="group text-gray-600 block flex justify-center items-center flex-shrink-0 transform duration-100 scale-100 active:scale-95">
                                <svg
                                    className="mr-1 w-5 h-5 transition transform duration-150 group-hover:scale-110 group-hover:text-teal-600"
                                    width="20"
                                    viewBox="0 0 24 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.061512 0.740892L11.5615 21.7596C11.7373 22.0801 12.2627 22.0801 12.4385 21.7596L23.9385 0.740892C24.0234 0.58597 24.0205 0.397326 23.9297 0.245335C23.8398 0.0933437 23.6767 0 23.5 0L0.499989 0C0.323231 0 0.160145 0.0933437 0.0703011 0.245335C-0.0205192 0.397326 -0.0234489 0.58597 0.061512 0.740892ZM16.9062 10.5L7.09374 10.5L12 1.54102L16.9062 10.5Z"
                                        fill="currentColor"
                                    />
                                </svg>
                                <span className="font-semibold group-hover:text-gray-900">
                                    {title}
                                </span>
                            </Link>
                            <div className="hidden md:block flex-1">
                                <div className="flex justify-center items-baseline">
                                    <Link
                                        to="/courses"
                                        className="flex-center group px-3 py-2 mx-1 rounded-md text-sm font-semibold text-gray-800 hover:bg-gray-100 focus:shadow-outline focus:outline-none hover:text-gray-800 focus:text-gray-800 transform duration-100 scale-100 active:scale-95">
                                        <svg
                                            className="w-5 h-5 transition duration-300 text-gray-500 group-hover:text-teal-600 mr-1"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                                            />
                                        </svg>
                                        Browse Courses
                                    </Link>

                                    <OutboundLink
                                        target="_blank"
                                        rel="noopener"
                                        href={youtube.url}
                                        to="/courses"
                                        className="flex-center group px-3 py-2 mx-1 rounded-md text-sm font-semibold text-gray-800 hover:bg-gray-100 focus:shadow-outline focus:outline-none hover:text-gray-800 focus:text-gray-800 transform duration-100 scale-100 active:scale-95">
                                        <svg
                                            className="w-5 h-5 mr-1 transition duration-300 text-gray-500 group-hover:text-red-700"
                                            viewBox="0 0 20 20">
                                            <path
                                                fill="currentColor"
                                                d="M10,2.3C0.172,2.3,0,3.174,0,10s0.172,7.7,10,7.7s10-0.874,10-7.7S19.828,2.3,10,2.3z M13.205,10.334
	l-4.49,2.096C8.322,12.612,8,12.408,8,11.974V8.026C8,7.593,8.322,7.388,8.715,7.57l4.49,2.096
	C13.598,9.85,13.598,10.15,13.205,10.334z"
                                            />
                                        </svg>
                                        YouTube Channel
                                    </OutboundLink>
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-4 flex items-center md:ml-6">
                                {user ? (
                                    <ProfileDropdown user={user} />
                                ) : (
                                    <Link
                                        to="/login"
                                        className="inline-block sm:inline-block hover:border-gray-400 leading-5 text-sm border rounded-md px-6 py-2 bg-white border-gray-200 transform duration-100 scale-100 active:scale-95">
                                        Login
                                    </Link>
                                )}
                            </div>
                        </div>
                        <div className="-mr-2 flex md:hidden">
                            <button
                                aria-label="Toggle Nav"
                                onClick={() => setNavOpen(!navOpen)}
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-300 focus:text-gray-600">
                                <svg
                                    className="h-6 w-6"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 24 24">
                                    <path
                                        className={`${navOpen ? 'hidden' : 'inline-flex'}`}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                    <path
                                        className={`${navOpen ? 'inline-flex' : 'hidden'}`}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                {navOpen && (
                    <div className="md:hidden">
                        <div className="px-2 pt-2 pb-3 sm:px-3">
                            <Link
                                to="/courses"
                                className="flex items-center group px-3 py-2 my-1 rounded-md text-base font-medium text-gray-700 focus:shadow-outline focus:outline-none focus:text-white focus:bg-gray-700">
                                <svg
                                    className="w-5 h-5 text-gray-500 group-hover:text-teal-600 mr-1"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                                    />
                                </svg>
                                Browse Courses
                            </Link>

                            <OutboundLink
                                target="_blank"
                                rel="noopener"
                                href={youtube.url}
                                to="/courses"
                                className="flex items-center group px-3 py-2 my-1 rounded-md text-base font-medium text-gray-700 focus:shadow-outline focus:outline-none focus:text-white focus:bg-gray-700">
                                <svg
                                    className="w-5 h-5 mr-1 text-gray-500 group-hover:text-red-700"
                                    viewBox="0 0 20 20">
                                    <path
                                        fill="currentColor"
                                        d="M10,2.3C0.172,2.3,0,3.174,0,10s0.172,7.7,10,7.7s10-0.874,10-7.7S19.828,2.3,10,2.3z M13.205,10.334
	l-4.49,2.096C8.322,12.612,8,12.408,8,11.974V8.026C8,7.593,8.322,7.388,8.715,7.57l4.49,2.096
	C13.598,9.85,13.598,10.15,13.205,10.334z"
                                    />
                                </svg>
                                YouTube Channel
                            </OutboundLink>
                        </div>
                        <div className="pt-4 pb-3 border-t border-gray-300">
                            {user ? (
                                <div>
                                    <div className="flex items-center px-5">
                                        <div className="flex-shrink-0">
                                            {user.photoURL ? (
                                                <img
                                                    className="h-10 w-10 rounded-full"
                                                    src={user.photoURL}
                                                    alt=""
                                                />
                                            ) : (
                                                <svg
                                                    className="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                    />
                                                </svg>
                                            )}
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium leading-none text-white">
                                                {user.name}
                                            </div>
                                            <div className="mt-1 text-sm font-medium leading-none text-gray-400">
                                                {user.email}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 px-2">
                                        <Link
                                            to="/app/my-courses"
                                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
                                            My Courses
                                        </Link>
                                        <Link
                                            to="/support"
                                            className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
                                            Support
                                        </Link>
                                        <button
                                            onClick={handleLogout}
                                            className="mt-1 block w-full px-3 py-2 rounded-md text-left text-base font-medium text-gray-600 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
                                            Logout
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="mt-1 px-2">
                                    <Link
                                        to="/login"
                                        className="mt-1 block px-4 py-2 rounded-md text-base font-medium text-gray-700 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
                                        <span className="">Log In</span>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </nav>
        </header>
    )
}
