import React from 'react'
import Header from './header'
import Footer from './footer'
// import SubscribeBox from './subscribe-box.js'

const Layout = props => {
    return (
        <div className="flex flex-col min-h-screen font-body">
            <Header dontFixHeader={props.dontFixHeader} />
            <main className="flex-1">{props.children}</main>
            <Footer />
        </div>
    )

    //@Todo pending subscribe box
    // <div className="w-full z-0">
    //     <SubscribeBox />
    // </div>
}

export default Layout
